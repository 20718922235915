// → commonly used value for css styling
export const mainWhite = `#FFFFFF`;
export const mainBlue = `#00235E`;
export const darkBlue = `#1C3144`;
export const mainOrange = `#F1993E`;
export const mainGrey = `#A6A7A8`;
export const lightGrey = `#F0F0F0`;

export const mainFont = `lato, sans-serif`;
// black 900
// heavy 800
// bold 700
// semibold 600
// medium 500
// regular 400
// light 300

export const siteWidth = `1920px`;

// → regex for email validation
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const transHover = `all 0.21s ease-out`;

// → media queries for devices
export const screen = {
  xsmall: `(min-width: 650px)`, // → tablet vertical
  small: `(min-width: 1160px)`, // → tablet horizontal
  medium: `(min-width: 1240px)`, // → small laptops
  large: `(min-width: 1530px)`, // → large laptop and regular size desktop
  minilarge: `(min-width: 1278px) and (max-height: 690px)`, // → laptop with short height
  xlarge: `(min-width: 1800px)`, // → large size desktop
  withCursor: `not all and (pointer: coarse)`, // → device with cursor. not touch screen devices
};
