// → navbar on large/desktop screen

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  mainBlue,
  mainWhite,
  mainOrange,
  lightGrey,
  transHover,
  screen,
  mainGrey,
} from "../common/variables";
import Logo from "../../images/svg/logo-white.svg";
import SearchIcon from "../../images/svg/search-icon.svg";
import CloseIcon from "../../images/svg/close-icon.svg";

const Wrapper = styled.nav`
  background-color: ${mainBlue};
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: none;
  @media ${screen.small} {
    padding: 0 0 0 calc(160px - 10%);
    display: flex;
  }
  @media ${screen.medium} {
    padding: 0 0 0 62px;
  }
  @media ${screen.large} {
    padding: 0 0 0 160px;
  }
  @media ${screen.xlarge} {
    padding: 0 0 0 280px;
  }

  .logo {
    display: block;
    max-width: 100px;
    width: 100%;
    visibility: hidden;
    @media ${screen.xsmall} {
      max-width: 85px;
    }
    @media ${screen.large} {
      max-width: 92px;
    }
    @media ${screen.xlarge} {
      max-width: 114px;
    }

    /* if Internet Explorer */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      svg {
        max-height: 50px;
        visibility: hidden;
      }
    }

    &--absolute {
      visibility: visible;
      position: absolute;
      z-index: 10;

      svg {
        .logo-colour {
          fill: ${props => (props.isSearchOpen ? lightGrey : mainWhite)};
        }
      }
    }
  }

  .link-list {
    display: flex;

    &__each {
      a {
        color: ${mainWhite};
        display: block;
        font-size: 0.98rem;
        margin-left: 38px;
        padding: 31px 0;
        white-space: pre;
        transition: ${transHover};
        @media ${screen.xsmall} {
          font-size: calc(0.8rem - 14%);
          margin-left: 30px;
        }
        @media ${screen.small} {
          font-size: calc(0.94rem - 14%);
          margin-left: 30px;
        }
        @media ${screen.medium} {
          font-size: calc(0.9rem - 10%);
          margin-left: 38px;
        }
        @media ${screen.large} {
          font-size: 1rem;
          margin-left: 38px;
        }
        @media ${screen.xlarge} {
          font-size: 1.08rem;
          margin-left: 48px;
        }

        &:hover {
          color: ${mainOrange};
        }
      }

      &--products {
        position: relative;

        a {
          &:hover {
            color: ${mainWhite};
          }
        }

        .toggle {
          display: inline-block;
          width: 14px;
          pointer-events: none;
        }

        .products-link-list {
          background-color: ${mainOrange};
          position: absolute;
          padding: 0 24px;
          width: 270px;
          transition: ${transHover};
          left: 14px;
          @media ${screen.xlarge} {
            left: 24px;
            width: 295px;
          }

          a {
            border-bottom: 2px ${mainWhite} solid;
            margin-left: 0;
            padding: 32px 0;
            font-weight: 700;
            white-space: nowrap;
            &:hover {
              color: ${mainBlue};
            }

            &:last-child {
              border-bottom: 0;
            }
          }

          &--contessi {
            width: 231px;
            @media ${screen.xlarge} {
              width: 234px;
            }
          }
        }
      }

      &--search {
        position: relative;
        width: 120px;
        background-color: ${props =>
          props.isSearchOpen ? mainOrange : mainBlue};
        transition: ${transHover};

        a {
          padding: 31px 0 31px 10px;
          @media ${screen.xlarge} {
            padding: 31px 0;
          }

          svg {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;

            @media ${screen.large} {
              width: 22px;
              height: 22px;
            }
            @media ${screen.xlarge} {
              width: 25px;
              height: 25px;
            }

            .search-colour {
              stroke: ${mainWhite};
              transition: ${transHover};
            }
          }

          &:hover .search-colour {
            stroke: ${props => (props.isSearchOpen ? mainWhite : mainOrange)};
          }
        }
      }

      &--enquiry {
        a {
          background-color: ${props =>
            props.isSearchOpen ? mainBlue : mainOrange};
          transition: ${transHover};
          padding: 31px 48px;
          margin-left: 0;
          position: relative;
          font-weight: 700;
          @media ${screen.withCursor} {
            &:hover {
              color: ${props => (props.isSearchOpen ? mainOrange : mainBlue)};
            }
          }

          .order-qty {
            color: ${mainWhite} !important;
            display: flex;
            width: 22px;
            height: 22px;
            position: absolute;
            background-color: ${props =>
              props.isSearchOpen ? mainOrange : mainBlue};
            font-size: 0.7rem;
            right: 26px;
            top: 26px;
            border-radius: 50%;
            transition: ${transHover};

            &__num {
              margin: auto;
            }
          }
        }
      }

      .active-orange {
        color: ${mainOrange} !important;
      }

      .active-blue {
        color: ${mainBlue};
      }
    }

    .input-search {
      background-color: ${mainWhite};
      color: red;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      transition: ${transHover};
      box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.04);

      input {
        height: 100%;
        display: block;
        border: 0;
        border-radius: 0;
        color: ${mainGrey};
        font-size: 1.4rem;
        font-weight: 300;
        outline: none;
        max-width: 1537px;
        width: ${props => `${props.inputWidth - 383}px`};
        padding-right: 79px;
        text-align: right;
        position: relative;
        z-index: 2;
        caret-color: ${mainBlue};

        ::placeholder {
          color: ${mainGrey};
        }
      }

      &__close-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        right: 409px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        transition: ${transHover};

        &:hover {
          opacity: 0.7;
        }

        svg {
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  #mobile {
  }
`;

// → orders prop = used for displaying products quantity on cart
// → toggleProductsDropdown prop = a function to toggle product dropdown
// → isProductsDropdownOpen prop = hide/show products dropdown
// → toggleSearchBar prop = a function to toggle search bar
// → isSearchOpen prop = hide/show search bar
// → handleInput prop = a function used for input element onchange
// → searchTerm  prop = used for input element value
// → handleSearch prop = a function used for search submit
// → inputEl prop =  used to focus input field
// → inputWidth prop = used to style input field width, for styling purposes
const DesktopNav = ({
  orders,
  toggleProductsDropdown,
  toggleContessiDropdown,
  isProductsDropdownOpen,
  isContessiDropdownOpen,
  toggleSearchBar,
  isSearchOpen,
  searchTerm,
  handleInput,
  handleSearch,
  inputEl,
  inputWidth,
}) => {
  return (
    <Wrapper isSearchOpen={isSearchOpen} inputWidth={inputWidth}>
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <Link className="logo logo--absolute" to="/" aria-label="Back Home">
        <Logo />
      </Link>
      <ul className="link-list">
        <li className="link-list__each">
          <Link to="/" activeClassName="active-orange">
            HOME
          </Link>
        </li>
        <li className="link-list__each">
          <Link to="/about" activeClassName="active-orange">
            ABOUT
          </Link>
        </li>
        <li className="link-list__each link-list__each--products">
          <Link to="/" onClick={toggleProductsDropdown}>
            THERMIC LANCE{" "}
            {isProductsDropdownOpen ? (
              <span className="toggle">&#8722;</span>
            ) : (
              <span className="toggle">+</span>
            )}
          </Link>
          <div
            className="products-link-list"
            style={{
              opacity: isProductsDropdownOpen ? 1 : 0,
              pointerEvents: isProductsDropdownOpen ? `all` : `none`,
            }}
          >
            <Link to="/what-is-thermic-lance" activeClassName="active-blue">
              WHAT IS A THERMIC LANCE?
            </Link>
            <Link to="/lance-products" activeClassName="active-blue">
              THERMIC LANCE PRODUCTS
            </Link>
          </div>
        </li>
        <li className="link-list__each link-list__each--products">
          <Link to="/" onClick={toggleContessiDropdown}>
            CONTESSI{" "}
            {isContessiDropdownOpen ? (
              <span className="toggle">&#8722;</span>
            ) : (
              <span className="toggle">+</span>
            )}
          </Link>
          <div
            className="products-link-list products-link-list--contessi"
            style={{
              opacity: isContessiDropdownOpen ? 1 : 0,
              pointerEvents: isContessiDropdownOpen ? `all` : `none`,
            }}
          >
            <Link to="/who-is-contessi/" activeClassName="active-blue">
              WHO IS CONTESSI?
            </Link>
            <Link to="/contessi-products" activeClassName="active-blue">
              CONTESSI PRODUCTS
            </Link>
          </div>
        </li>
        <li className="link-list__each">
          <Link to="/foundry-products" activeClassName="active-orange">
            FOUNDRY PRODUCTS
          </Link>
        </li>

        <li className="link-list__each">
          <Link to="/training" activeClassName="active-orange">
            TRAINING
          </Link>
        </li>
        {/* <li className="link-list__each">
          <Link to="/insights" activeClassName="active-orange">
            INSIGHTS
          </Link>
        </li> */}
        <li className="link-list__each">
          <Link to="/contact" activeClassName="active-orange">
            CONTACT
          </Link>
        </li>
        <li>
          <form
            onSubmit={handleSearch}
            className="input-search"
            style={{
              transform: isSearchOpen ? `translateX(0)` : `translateX(100%)`,
            }}
          >
            <input
              ref={inputEl}
              type="text"
              placeholder="What are you looking for?"
              value={searchTerm}
              onChange={handleInput}
            />
            <span className="input-search__close-icon">
              <CloseIcon onClick={toggleSearchBar} />
            </span>
          </form>
        </li>
        <li className="link-list__each link-list__each--search">
          <Link to="/" onClick={isSearchOpen ? handleSearch : toggleSearchBar}>
            <SearchIcon />
          </Link>
        </li>
        <li className="link-list__each link-list__each--enquiry">
          <Link to="/enquiry" activeClassName="active-blue">
            PRODUCT ENQUIRY
            {orders !== 0 && (
              <span className="order-qty">
                <span className="order-qty__num">{orders}</span>
              </span>
            )}
          </Link>
        </li>
      </ul>
    </Wrapper>
  );
};

export default DesktopNav;
