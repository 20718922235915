import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../css/normalize.css";
import "../css/global.css";
import NavBar from "../components/nav-bar";
import Footer from "../components/footer";
import {
  mainFont,
  mainWhite,
  transHover,
  mainGrey,
  siteWidth,
  screen,
} from "../components/common/variables";
import { graphql, useStaticQuery } from "gatsby";
import BackTopArrow from "../images/svg/back-top-arrow.svg";
import { Waypoint } from "react-waypoint";

import { Link as SmoothLink } from "react-scroll";

const SiteContainer = styled.div`
  background-color: ${mainWhite};
  max-width: ${siteWidth};
  margin: 0 auto;
  font-family: ${mainFont};
  position: relative;

  #page-wrapper {
    position: relative;

    #back-top-arrow {
      display: none;
      background: red;

      @media ${screen.xsmall} {
        max-width: ${siteWidth};
        width: 100%;
        display: block;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        transition: ${transHover};
        z-index: 5;
        bottom: -4px;
      }
      @media ${screen.small} {
      }
      svg {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 40px;
        bottom: 40px;

        .box {
          transition: ${transHover};
          @media ${screen.withCursor} {
            &:hover {
              fill: ${mainGrey};
            }
          }
        }
      }
    }
  }

  #test {
    position: absolute;
    right: ${(props) => `-${props.innerWidth}px`};
    top: 0;
    z-index: 9999;
    background: #ccc;
    height: 100px;
    width: ${(props) => `${props.innerWidth}px`};
    display: none;
    height: 100%;

    @media (min-width: 1920px) {
      display: block;
    }
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(dataQuery);

  const [isFooterShowing, setFooter] = useState(false);
  const [innerWidth, setInnerWidth] = useState(false);

  const { general } = data;

  const toggleBackTopArrow = () => {
    setFooter(!isFooterShowing);
  };

  useEffect(() => {
    const getSideWidth = () => {
      if (window.innerWidth > 1919) {
        setInnerWidth((window.innerWidth - 1920) / 2);
      }
    };
    window.addEventListener("resize", getSideWidth);

    return () => {
      window.removeEventListener("resize", getSideWidth);
    };
  }, [innerWidth]);

  useEffect(() => {
    if (window.innerWidth > 1919) {
      setInnerWidth((window.innerWidth - 1920) / 2);
    } else {
      setInnerWidth(0);
    }
  }, []);

  return (
    <SiteContainer innerWidth={innerWidth}>
      <NavBar />
      <main id="page-wrapper">
        {children}
        <span
          id="back-top-arrow"
          style={{
            opacity: isFooterShowing ? 1 : 0,
            pointerEvents: isFooterShowing ? "all" : "none",
          }}
        >
          <SmoothLink to="page-wrapper" smooth>
            <BackTopArrow />
          </SmoothLink>
        </span>
      </main>
      <Waypoint onEnter={toggleBackTopArrow} onLeave={toggleBackTopArrow}>
        <div>
          <Footer socialLinks={general.data.social_links} />
        </div>
      </Waypoint>
      <div id="test"></div>
    </SiteContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export const dataQuery = graphql`
  {
    general: prismicGeneralDetails {
      data {
        social_links {
          social_media
          full_url
        }
      }
    }
  }
`;
