// → overlay menu on small/mobile screen

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  mainBlue,
  mainWhite,
  mainOrange,
  mainGrey,
  transHover,
  screen,
} from "../common/variables";
import SearchIcon from "../../images/svg/search-icon.svg";
import { TouchScrollable } from "react-scrolllock";
import RightArrow from "../../images/svg/right-arrow.svg";
import { FaInstagram, FaFacebookSquare, FaLinkedin } from "react-icons/fa";

const Wrapper = styled.nav`
  background-color: ${mainBlue};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: ${transHover};
  z-index: -1;
  overflow: scroll;
  @media ${screen.small} {
    display: none;
  }

  .menu-list {
    margin-top: 120px;
    margin-left: 35px;
    @media ${screen.xsmall} {
      margin-top: 160px;
      margin-left: 160px;
    }

    &__each {
      margin-bottom: 27px;

      @media ${screen.xsmall} {
        margin-bottom: 45px;
      }
      a {
        color: ${mainWhite};
        font-size: 1.37rem;
        @media ${screen.xsmall} {
          font-size: 1.86rem;
        }

        .toggle {
          display: inline-block;
          margin-left: 20px;
          pointer-events: none;
        }
      }
    }

    &--product-dropdown {
      a {
        font-size: 0.9rem;
        display: block;
        font-weight: 800;
        margin-bottom: 20px;
        @media ${screen.xsmall} {
          font-size: 1.24rem;
        }
        &:first-child {
          margin-top: 20px;
          @media ${screen.xsmall} {
          }
        }

        span {
          max-width: 60px;
          width: 100%;
          margin-right: 14px;
          display: inline-block;
          @media ${screen.xsmall} {
            max-width: 90px;
            margin-right: 20px;
          }

          svg {
            .right-arrow {
              stroke: ${mainWhite};
            }
          }
        }
      }
    }
  }

  .menu-footer {
    margin-top: 84px;
    @media ${screen.xsmall} {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &__row1 {
      p {
        display: flex;
        input {
          border: 0;
          border-radius: 0;
          color: ${mainGrey};
          text-align: right;
          width: 100%;
          padding: 26px 18px 26px 0;
          font-size: 1.25rem;
          outline: none;
          @media ${screen.xsmall} {
            font-size: 1.6rem;
            padding: 36px 36px 36px 0;
          }
          ::placeholder {
            color: ${mainGrey};
            font-size: 1.25rem;
            line-height: 1.4;
            @media ${screen.xsmall} {
              font-size: 1.6rem;
            }
          }
        }

        span {
          background-color: ${mainGrey};
          max-width: 90px;
          display: block;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @media ${screen.xsmall} {
            max-width: 136px;
          }

          svg {
            width: 100%;
            max-width: 27px;
            @media ${screen.xsmall} {
              max-width: 42px;
            }

            .search-colour {
              stroke: ${mainWhite};
            }
          }
        }
      }
    }

    &__row2 {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
        align-items: center;
      }

      .social-list {
        display: flex;
        justify-content: center;
        width: 100%;
        order: 1;
        padding: 26px 0;
        @media ${screen.xsmall} {
          order: 0;
          width: 48%;
        }

        li {
          a {
            color: ${mainWhite};
            font-size: 3rem;
            display: block;
            margin: 0 16px;
            @media ${screen.xsmall} {
              font-size: 2.5rem;
              margin: 0 10px;
            }
          }
        }
      }

      .enquiry-link {
        width: 100%;
        background-color: ${mainOrange};
        @media ${screen.xsmall} {
          width: 52%;
        }

        a {
          display: block;
          color: ${mainWhite};
          text-align: center;
          padding: 32px 0;
          position: relative;
          font-size: 1.37rem;
          @media ${screen.xsmall} {
            font-size: 2rem;
            padding: 52px 0;
          }
        }
        &__label {
          position: relative;

          .order-qty {
            color: ${mainWhite};
            display: flex;
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: ${mainBlue};
            font-size: 0.75rem;
            right: -15px;
            top: 0;
            border-radius: 50%;
            @media ${screen.xsmall} {
              right: -19px;
              top: -4px;
              width: 28px;
              height: 28px;
              font-size: 0.95rem;
            }

            &__num {
              margin: auto;
            }
          }
        }
      }
    }
  }

  .active-orange {
    color: ${mainOrange} !important;
    .right-arrow {
      stroke: ${mainOrange} !important;
    }
  }

  .active-blue {
    color: ${mainBlue} !important;
  }
`;

// → toggleOverlayMenu prop = a function to toggle menu overlay
// → isOverlayOpen prop = hide/show menu overlay
// → toggleProductsDropdown prop = a function to toggle product dropdown
// → isProductsDropdownOpen prop = hide/show products dropdown
// → orders prop = used for displaying products quantity on cart
// → handleInput prop = a function used for input element onchange
// → searchTerm  prop = used for input element value
// → handleSearch prop = a function used for search submit
const OverlayMenu = ({
  toggleOverlayMenu,
  isOverlayOpen,
  toggleProductsDropdown,
  toggleContessiDropdown,
  isProductsDropdownOpen,
  isContessiDropdownOpen,
  orders,
  handleInput,
  searchTerm,
  handleSearch,
}) => {
  return (
    <TouchScrollable>
      <Wrapper
        style={{
          transform: isOverlayOpen ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <ul className="menu-list">
          <li className="menu-list__each">
            <Link
              to="/"
              activeClassName="active-orange"
              onClick={toggleOverlayMenu}
            >
              HOME
            </Link>
          </li>
          <li className="menu-list__each">
            <Link
              to="/about"
              activeClassName="active-orange"
              onClick={toggleOverlayMenu}
            >
              ABOUT
            </Link>
          </li>
          <li className="menu-list__each">
            <Link to="/" onClick={toggleProductsDropdown}>
              THERMIC LANCE
              {isProductsDropdownOpen ? (
                <span className="toggle">&#8722;</span>
              ) : (
                <span className="toggle">+</span>
              )}
            </Link>
            {isProductsDropdownOpen && (
              <div className="menu-list--product-dropdown">
                <Link
                  to="/what-is-thermic-lance"
                  activeClassName="active-orange"
                  onClick={toggleOverlayMenu}
                >
                  {" "}
                  <span>
                    <RightArrow />
                  </span>
                  WHAT IS A THERMIC LANCE?
                </Link>
                <Link
                  to="/lance-products"
                  activeClassName="active-orange"
                  onClick={toggleOverlayMenu}
                >
                  {" "}
                  <span>
                    <RightArrow />
                  </span>
                  THERMIC LANCE PRODUCTS
                </Link>
              </div>
            )}
          </li>
          <li className="menu-list__each">
            <Link to="/" onClick={toggleContessiDropdown}>
              CONTESSI
              {isContessiDropdownOpen ? (
                <span className="toggle">&#8722;</span>
              ) : (
                <span className="toggle">+</span>
              )}
            </Link>
            {isContessiDropdownOpen && (
              <div className="menu-list--product-dropdown">
                <Link
                  to="/who-is-contessi/"
                  activeClassName="active-orange"
                  onClick={toggleOverlayMenu}
                >
                  {" "}
                  <span>
                    <RightArrow />
                  </span>
                  WHO IS CONTESSI
                </Link>
                <Link
                  to="/contessi-products"
                  activeClassName="active-orange"
                  onClick={toggleOverlayMenu}
                >
                  {" "}
                  <span>
                    <RightArrow />
                  </span>
                  CONTESSI PRODUCTS
                </Link>
              </div>
            )}
          </li>
          <li className="menu-list__each">
            <Link
              to="/foundry-products"
              activeClassName="active-orange"
              onClick={toggleOverlayMenu}
            >
              FOUNDRY PRODUCTS
            </Link>
          </li>
          <li className="menu-list__each">
            <Link
              to="/training"
              activeClassName="active-orange"
              onClick={toggleOverlayMenu}
            >
              TRAINING
            </Link>
          </li>
          {/* <li className="menu-list__each">
            <Link to="/insights" activeClassName="active-orange">
              INSIGHTS
            </Link>
          </li> */}
          <li className="menu-list__each">
            <Link
              to="/contact"
              activeClassName="active-orange"
              onClick={toggleOverlayMenu}
            >
              CONTACT
            </Link>
          </li>
        </ul>

        <div className="menu-footer">
          <form className="menu-footer__row1" onSubmit={handleSearch}>
            <p>
              <input
                type="text"
                placeholder="What are you looking for?"
                value={searchTerm}
                onChange={handleInput}
              />
              <span onClick={handleSearch}>
                <SearchIcon />
              </span>
            </p>
          </form>
          <div className="menu-footer__row2">
            <ul className="social-list">
              <li>
                <a
                  href="https://www.facebook.com/Australian-Thermic-Lance-102419374698758"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit Instagram Account"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Australian-Thermic-Lance-102419374698758"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit Facebook Account"
                >
                  <FaFacebookSquare />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/australian-thermiclance/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit LinkedIn Account"
                >
                  <FaLinkedin />
                </a>
              </li>
            </ul>
            <div className="enquiry-link">
              <Link to="/enquiry" activeClassName="active-blue">
                <span className="enquiry-link__label">
                  PRODUCT ENQUIRY{" "}
                  {orders !== 0 && (
                    <span className="order-qty">
                      <span className="order-qty__num">{orders}</span>
                    </span>
                  )}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </TouchScrollable>
  );
};

export default OverlayMenu;
