// → fullscreen overlay for searching loader

import React from "react";
import styled from "styled-components";
import { mainBlue, screen } from "../../components/common/variables";
import Spinner from "../../images/svg/spinner.svg";

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.92);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .content {
    text-align: center;

    &__text {
      color: ${mainBlue};
      font-size: 1.15rem;
      margin-bottom: 18px;
      @media ${screen.xsmall} {
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
      @media ${screen.medium} {
        font-size: 2rem;
      }
      span {
        font-weight: 800;
      }
    }
    &__search-spinner {
      svg {
        width: 82px;
        height: 82px;
        @media ${screen.xsmall} {
          width: 120px;
          height: 120px;
        }
        @media ${screen.medium} {
          width: 180px;
          height: 180px;
        }
      }
    }
  }
`;

// → searchTerm prop = user input to display on loading
const LoaderOverlay = ({ searchTerm }) => {
  return (
    <Wrapper>
      <div className="content">
        <p className="content__text">
          Searching for "<span>{searchTerm}</span>"
        </p>
        <span className="content__search-spinner">
          <Spinner />
        </span>
      </div>
    </Wrapper>
  );
};

export default LoaderOverlay;
