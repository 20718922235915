// → footer section

import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  lightGrey,
  mainBlue,
  mainWhite,
  mainGrey,
  mainOrange,
  transHover,
  screen,
} from "../common/variables";
import Logo from "../../images/svg/logo-colour.svg";
import {
  FaInstagram,
  FaFacebookSquare,
  FaLinkedin,
  FaVimeoSquare,
  FaTwitterSquare,
} from "react-icons/fa";

const Wrapper = styled.footer`
  @media ${screen.small} {
    background-color: ${lightGrey};
  }

  .footer-inner {
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 65px;
      padding-right: 65px;
    }
    @media ${screen.large} {
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    &__row {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        align-items: flex-end;
        flex-direction: row;
      }
      @media ${screen.small} {
        justify-content: space-evenly;
      }

      @media ${screen.large} {
        justify-content: center;
      }

      .col1 {
        max-width: 240px;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;
        display: none;
        @media ${screen.small} {
          display: block;
        }
        @media ${screen.large} {
          padding-top: 20px;
          padding-bottom: 60px;
        }

        &__logo {
          width: 100%;
          width: 290px;
          margin-left: -33px;
          @media ${screen.small} {
            width: 270px;
          }
          @media ${screen.medium} {
            width: 290px;
          }
          @media ${screen.large} {
            width: 320px;
          }
        }

        &__text {
          font-size: 1.15rem;
          font-weight: 300;
          @media ${screen.large} {
            font-size: 1.25rem;
          }

          &--bold {
            font-weight: 700;
            a {
              color: ${mainBlue};
              transition: ${transHover};
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainOrange};
                }
              }
            }
          }
        }

        &__social-heading {
          color: ${mainBlue};
          font-size: 1.15rem;
          font-weight: 300;
          margin-top: 20px;
          margin-bottom: 5px;
          @media ${screen.large} {
            font-size: 1.25rem;
          }
        }

        &__social-list {
          display: flex;
          flex-wrap: wrap;
          li {
            a {
              color: ${mainGrey};
              font-size: 2rem;
              display: block;
              margin-right: 14px;
              margin-top: 5px;
              transition: ${transHover};
              @media ${screen.large} {
                font-size: 2.2rem;
                margin-right: 10px;
              }
              &:hover {
                color: ${mainOrange};
              }
            }
          }
        }
      }

      .col2 {
        background-color: ${mainBlue};
        width: calc(100% - 35px);
        padding-top: 10px;
        padding-bottom: 32px;
        position: relative;
        @media ${screen.xsmall} {
          width: 55%;
          padding-top: 30px;
          padding-bottom: 50px;
        }
        @media ${screen.small} {
          margin-left: 30px;
          margin-right: 30px;
          padding-top: 56px;
          padding-bottom: 80px;
          max-width: 520px;
          width: 100%;
        }
        @media ${screen.medium} {
          margin-left: 4vw;
          margin-right: 4vw;
          max-width: 570px;
          padding-bottom: 110px;
        }
        @media ${screen.large} {
          padding-bottom: 60px;
        }

        &::before {
          background-color: ${mainBlue};
          content: "";
          display: block;
          width: 100%;
          height: 24px;
          position: absolute;
          top: -24px;
        }

        &::after {
          background-color: ${lightGrey};
          content: "";
          display: block;
          width: 35px;
          height: calc(100% - 100px);
          position: absolute;
          bottom: 0;
          right: -35px;
        }

        &__heading {
          color: ${mainWhite};
          font-size: 1.8rem;
          padding: 0 35px;
          margin-bottom: 16px;
          @media ${screen.xsmall} {
            padding: 0 72px;
            font-size: 2.2rem;
            margin-bottom: 20px;
          }
          @media ${screen.small} {
            font-size: 2.3rem;
            margin-bottom: 30px;
          }
          @media ${screen.medium} {
            font-size: 2.8rem;
            margin-bottom: 40px;
          }
        }

        &__description {
          padding: 0 35px;
          @media ${screen.xsmall} {
            padding: 0 72px;
          }
          p {
            color: ${mainWhite};
            font-size: 1rem;
            font-weight: 300;
            margin-bottom: 16px;
            @media ${screen.xsmall} {
              font-size: 1.15rem;
              margin-bottom: 20px;
            }

            a {
              color: ${mainWhite};
              transition: ${transHover};
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainOrange};
                }
              }
            }
          }
        }
      }

      .col3 {
        width: 100%;
        padding-right: 35px;
        padding-left: 35px;
        background-color: ${lightGrey};
        padding-top: 30px;
        padding-bottom: 15px;
        @media ${screen.xsmall} {
          width: 45%;
          padding-left: 45px;
          padding-right: 90px;
          padding-bottom: 30px;
        }
        @media ${screen.small} {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 60px;
          padding-right: 0;
          padding-left: 0;
          max-width: 260px;
        }
        @media ${screen.medium} {
          padding-bottom: 60px;
        }
        @media ${screen.large} {
          padding-top: 20px;
        }

        &__heading {
          color: ${mainBlue};
          font-size: 1.8rem;
          margin-bottom: 16px;
          @media ${screen.xsmall} {
            font-size: 2.2rem;
            margin-bottom: 20px;
          }
          @media ${screen.small} {
            font-size: 2.3rem;
          }
          @media ${screen.medium} {
            font-size: 2.8rem;
          }
        }

        &__description {
          color: ${mainBlue};
          font-size: 1rem;
          font-weight: 300;
          margin-bottom: 16px;
          @media ${screen.large} {
            font-size: 1.15rem;
          }
        }

        &__form {
          input {
            border: 0;
            border-radius: 0;
            border-bottom: 2px ${mainBlue} solid;
            background-color: ${mainWhite};
            color: ${mainBlue};
            display: block;
            margin-bottom: 10px;
            width: 100%;
            font-size: 1rem;
            padding: 9px;
            @media ${screen.xsmall} {
              width: 260px;
              margin-bottom: 20px;
            }
            @media ${screen.small} {
              width: 230px;
            }
            @media ${screen.medium} {
              width: 260px;
            }
            @media ${screen.large} {
              width: 300px;
              font-size: 1.1rem;
            }

            &::placeholder {
              color: ${mainBlue};
            }
          }

          button {
            background-color: ${mainGrey};
            color: ${mainWhite};
            border: 0;
            cursor: pointer;
            font-size: 0.93rem;
            padding: 10px 45px;
            transition: ${transHover};
            outline-color: ${mainBlue};
            width: 100%;
            @media ${screen.xsmall} {
              width: inherit;
            }
            &:hover {
              background-color: ${mainOrange};
            }
          }
        }
      }
    }
  }

  .contact-sm {
    background-color: ${lightGrey};
    color: ${mainBlue};
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    @media ${screen.xsmall} {
      padding-top: 20px;
      padding-left: 50px;
      padding-right: 90px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    @media ${screen.small} {
      display: none;
    }

    &__logo {
      width: 100%;
      @media ${screen.xsmall} {
        width: 45%;
      }
      @media ${screen.small} {
      }
      svg {
        max-width: 230px;
        margin: 0 auto;
        display: block;
        @media ${screen.xsmall} {
          margin: 0;
        }
      }
    }

    &__details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 12px 35px 0 35px;
      @media ${screen.xsmall} {
        width: 55%;
        padding: 0;
      }

      .contact-heading {
        .text {
          color: ${mainBlue};
          font-size: 1.15rem;
          font-weight: 300;
          &--bold {
            font-weight: 700;
          }
        }
      }

      .contact-social-list {
        display: flex;

        li {
          a {
            color: ${mainGrey};
            font-size: 2.1rem;
            display: block;
            margin: 0 8px;
            @media ${screen.xsmall} {
              font-size: 2.3rem;
              margin: 0 10px;
            }
            svg {
            }
          }
        }
      }
    }
  }

  .footer-outer {
    background-color: ${mainWhite};
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 35px;
    padding-right: 35px;
    align-items: center;
    @media ${screen.xsmall} {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 70px;
      padding-right: 70px;
      flex-direction: row;
      justify-content: space-between;
    }
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    &__copyright {
      display: flex;
      flex-wrap: wrap;

      li {
        color: ${mainGrey};
        font-size: 0.56rem;
        font-weight: 300;
        @media ${screen.xsmall} {
          font-size: 0.78rem;
        }

        a {
          color: ${mainGrey};
          transition: ${transHover};
          margin-left: 3px;
          &:hover {
            color: ${mainOrange};
          }
        }
      }
    }

    &__creator {
      color: ${mainGrey};
      font-size: 0.62rem;
      font-weight: 300;
      margin-top: 9px;
      @media ${screen.xsmall} {
        margin-top: 0;
        font-size: 0.78rem;
      }
      a {
        color: ${mainOrange};
        font-weight: 700;
        transition: ${transHover};
        &:hover {
          color: ${mainBlue};
        }
      }
    }
  }
`;
// → socialLinks prop expected an array of objects from CMS
const Footer = ({ socialLinks }) => {
  // → for auto update copyright year
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <div className="footer-inner">
        <div className="footer-inner__row">
          <div className="col1">
            <Logo className="col1__logo" />
            <p className="col1__text">Got questions?</p>
            <p className="col1__text col1__text--bold">
              <Link to="/contact">Contact the team</Link>
            </p>
            <h6 className="col1__social-heading">Find us on</h6>
            <ul className="col1__social-list">
              {socialLinks.map((social, i) => (
                <li key={i}>
                  <a
                    href={social.full_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Visit Social Media Account"
                  >
                    {social.social_media === `Facebook` && <FaFacebookSquare />}
                    {social.social_media === `Twitter` && <FaTwitterSquare />}
                    {social.social_media === `LinkedIn` && <FaLinkedin />}
                    {social.social_media === `Vimeo` && <FaVimeoSquare />}
                    {social.social_media === `Instagram` && <FaInstagram />}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col2">
            <h6 className="col2__heading">Find a distributor</h6>
            <div className="col2__description">
              <p>
                We have acquired many competent distributors over the years,
                with major distributors found nationwide and internationally to
                service our retail market directly.
              </p>
              <p>
                To find out more information or your nearest distributor, please{" "}
                <strong>
                  <Link to="/contact">contact us</Link>
                </strong>{" "}
                or{" "}
                <strong>
                  <Link to="/enquiry">request a quote.</Link>
                </strong>
              </p>
            </div>
          </div>
          <div className="col3">
            <h6 className="col3__heading">Stay updated</h6>
            <p className="col3__description">
              Subscribe to our Newsletter and be updated with the latest
              industry news, insights and ATLC products.
            </p>
            <form
              className="col3__form js-cm-form"
              id="subForm"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="2BE4EF332AA2E32596E38B640E905619A0D53CEA67035755DD2054190611D40B5E626214E8A384BB9D0492A6A287277F77B6903DFBF34A3DEC7D9CFB36B09B57"
            >
              <input
                placeholder="What's your email address?"
                aria-label="Email"
                id="fieldEmail"
                maxLength="200"
                name="cm-wthjik-wthjik"
                required
                type="email"
                className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
              />
              <button>SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-sm">
        <div className="contact-sm__logo">
          <Logo />
        </div>

        <div className="contact-sm__details">
          <div className="contact-heading">
            <p className="text">Got questions?</p>
            <p className="text--bold">Contact the team</p>
          </div>
          <ul className="contact-social-list">
            {socialLinks.map((social, i) => (
              <li key={i}>
                <a
                  href={social.full_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Visit Social Media Account"
                >
                  {social.social_media === `Facebook` && <FaFacebookSquare />}
                  {social.social_media === `Twitter` && <FaTwitterSquare />}
                  {social.social_media === `LinkedIn` && <FaLinkedin />}
                  {social.social_media === `Vimeo` && <FaVimeoSquare />}
                  {social.social_media === `Instagram` && <FaInstagram />}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer-outer">
        <ul className="footer-outer__copyright">
          <li>&copy; Australian Thermic Lance {year} / </li>
          <li>
            {"   "}
            <a
              href="https://prismic-io.s3.amazonaws.com/australian-thermic-lance-company/c8b893b7-dc94-4edc-ab19-d132c94f9571_ATLC-Privacy-Policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="View Privacy Policy"
            >
              {" "}
              Privacy Policy
            </a>{" "}
          </li>
          {/* <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li> */}
        </ul>
        <p className="footer-outer__creator">
          Website designed and built by{" "}
          <a
            href="https://www.hellohandsome.com.au"
            target="_blank"
            aria-label="Visit Handsome Creative Website"
          >
            Handsome Creative.
          </a>
        </p>
      </div>
    </Wrapper>
  );
};

export default Footer;
