// → navbar on small/mobile screen

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { mainBlue, mainWhite, screen } from "../common/variables";
import Logo from "../../images/svg/logo-white.svg";

const Wrapper = styled.nav`
  display: flex;
  background-color: ${mainBlue};
  position: relative;
  height: 75px;
  z-index: 10;
  @media ${screen.xsmall} {
    height: 88px;
  }
  @media ${screen.small} {
    display: none;
  }

  .menu-logo {
    display: block;
    width: 100%;
    max-width: 105px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    left: 35px;
    @media ${screen.xsmall} {
      max-width: 120px;
      left: 70px;
    }
  }

  .menu-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
    z-index: 10;
    color: ${mainWhite};
    width: 40px;
    height: calc(4px + 10px * 2);
    cursor: pointer;
    @media ${screen.xsmall} {
      right: 70px;
      width: 48px;
      height: calc(4px + 12px * 2);
    }

    .hamburger-menu,
    .hamburger-menu:after,
    .hamburger-menu:before {
      width: 40px;
      height: 4px;
      @media ${screen.xsmall} {
        width: 48px;
      }
    }

    .hamburger-menu {
      position: relative;
      transform: translateY(10px);
      background: rgba(255, 255, 255, 1);
      transition: all 0ms 300ms;
      @media ${screen.xsmall} {
        transform: translateY(12px);
      }

      &.animate {
        background: rgba(255, 255, 255, 0);
      }
    }

    .hamburger-menu:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 10px;
      background: rgba(255, 255, 255, 1);
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      @media ${screen.xsmall} {
        bottom: 12px;
      }
    }

    .hamburger-menu:after {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      background: rgba(255, 255, 255, 1);
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      @media ${screen.xsmall} {
        top: 12px;
      }
    }

    .hamburger-menu.animate:after {
      top: 0;
      transform: rotate(45deg);
      transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .hamburger-menu.animate:before {
      bottom: 0;
      transform: rotate(-45deg);
      transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
        transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
`;

// → toggleOverlayMenu prop = a function to toggle menu overlay
// → isOverlayOpen prop = hide/show menu overlay
const MobileNav = ({ isOverlayOpen, toggleOverlayMenu }) => {
  return (
    <Wrapper>
      <span className="menu-logo">
        <Link to="/">
          <Logo />
        </Link>
      </span>

      <div className="menu-wrapper" onClick={toggleOverlayMenu}>
        <div
          className={
            isOverlayOpen ? "hamburger-menu animate" : "hamburger-menu"
          }
        ></div>
      </div>
    </Wrapper>
  );
};

export default MobileNav;
