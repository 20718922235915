// → header/nav parent container

import React, { useState, useContext, useEffect, useRef } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { StoreContext } from "../../context";
import DesktopNav from "./on-desktop";
import MobileNav from "./on-mobile";
import LoaderOverlay from "./loader-overlay";
import OverlayMenu from "./overlay-menu";
import slugify from "slugify";

const Wrapper = styled.nav`
  max-width: 1920px;
  width: 100%;
  position: fixed;
  z-index: 25;
`;

const NavBar = () => {
  const { orders, setProductSidebar, isProductSidebarOpen } = useContext(
    StoreContext
  );

  const [isProductsDropdownOpen, setProductsDropdown] = useState(false);
  const [isContessiDropdownOpen, setContessiDropdown] = useState(false);
  const [isSearchOpen, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputWidth, setInputWidth] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isOverlayOpen, setOverlay] = useState(false);

  // → get browser width, for styling purposes
  useEffect(() => {
    setInputWidth(typeof window !== "undefined" && window.innerWidth);
  }, []);

  // → input ref, use for focus when user open the search bar
  const inputEl = useRef(null);

  // → toggler for mobile menu overlay
  const toggleOverlayMenu = () => {
    setOverlay(!isOverlayOpen);
    // → reset search term input on close/open
    setSearchTerm("");
  };

  // → toggler for products dropdown on mobile
  const toggleProductsDropdown = e => {
    e.preventDefault();
    setProductsDropdown(!isProductsDropdownOpen);

    if (isContessiDropdownOpen) {
      setContessiDropdown(false);
    }
  };

  const toggleContessiDropdown = e => {
    e.preventDefault();
    setContessiDropdown(!isContessiDropdownOpen);

    if (isProductsDropdownOpen) {
      setProductsDropdown(false);
    }
  };

  // → toggler for search bar
  const toggleSearchBar = e => {
    e.preventDefault();
    setSearch(!isSearchOpen);
    setSearchTerm("");

    // → auto focus the input once the search bar is open
    if (!isSearchOpen) {
      inputEl.current.focus();
    }
  };

  // → search term input handler
  const handleInput = e => {
    setSearchTerm(e.target.value);
  };

  // → search submit handler
  const handleSearch = e => {
    e.preventDefault();
    if (isProductSidebarOpen) {
      setProductSidebar(false);
    }

    setOverlay(false);
    // → only start search if user has put value in the field
    if (searchTerm.length) {
      setLoading(true);
      // → timer to imitate a loading screen
      setTimeout(() => {
        setSearchTerm("");
        setSearch(false);
        // → navigate to the search page with search url path
        navigate(
          `/search?${slugify(searchTerm, {
            replacement: "-", // → replace spaces with -
            lower: true, // → result in lower case
          })}`
        );

        setLoading(false);
      }, 820);
    }
  };

  // → watcher for user click
  useEffect(() => {
    const targetClick = e => {
      // → close dropdown if user click anywhere else but A tag
      if (e.target.tagName !== "A") {
        setProductsDropdown(false);
        setContessiDropdown(false);
      }
    };
    window.addEventListener("click", targetClick);

    return () => {
      window.removeEventListener("click", targetClick);
    };
  }, []);

  // → watcher for user resizing the browser, for styling purposes
  useEffect(() => {
    const browserResize = () => {
      setInputWidth(window.innerWidth);
    };
    window.addEventListener("resize", browserResize);

    return () => {
      window.removeEventListener("resize", browserResize);
    };
  }, []);

  return (
    <Wrapper>
      <MobileNav
        isOverlayOpen={isOverlayOpen}
        toggleOverlayMenu={toggleOverlayMenu}
      />
      <OverlayMenu
        isOverlayOpen={isOverlayOpen}
        toggleOverlayMenu={toggleOverlayMenu}
        isProductsDropdownOpen={isProductsDropdownOpen}
        isContessiDropdownOpen={isContessiDropdownOpen}
        toggleProductsDropdown={toggleProductsDropdown}
        toggleContessiDropdown={toggleContessiDropdown}
        orders={orders.length}
        searchTerm={searchTerm}
        handleInput={handleInput}
        handleSearch={handleSearch}
      />
      <DesktopNav
        isProductsDropdownOpen={isProductsDropdownOpen}
        isContessiDropdownOpen={isContessiDropdownOpen}
        toggleProductsDropdown={toggleProductsDropdown}
        toggleContessiDropdown={toggleContessiDropdown}
        toggleSearchBar={toggleSearchBar}
        inputWidth={inputWidth}
        orders={orders.length}
        inputEl={inputEl}
        isSearchOpen={isSearchOpen}
        searchTerm={searchTerm}
        handleInput={handleInput}
        handleSearch={handleSearch}
      />

      {isLoading && (
        <LoaderOverlay isOverlayOpen={isOverlayOpen} searchTerm={searchTerm} />
      )}
    </Wrapper>
  );
};

export default NavBar;
